import Notification from "../../../components/notifications";
import { jsPDF } from "jspdf";
import "jspdf-autotable";

export default {
  name: "TLoanInstallmentAnuitySimulation",
  data() {
    return {
      dataForm: {
        request: {
          nominalLoan: 0,
          rate: 0,
          loanPeriod: 0,
        },
        response: {
          nominalLoan: 0,
          totalRate: 0,
          totalPeriod: 0,
          totalInstallment: 0,
        },
      },
      table: {
        header: {
          installmentAunuitySimulation: [
            {
              key: "no",
              label: "No",
            },
            {
              key: "principalRemain",
              label: "Sisa Pokok",
            },
            {
              key: "principal",
              label: "Angsuran Pokok",
            },
            {
              key: "margin",
              label: "Angsuran Margin",
            },
            {
              key: "installment",
              label: "Total Angsuran",
            },
          ],
        },
        data: {
          installmentAunuitySimulation: [],
        },
      },
      property: {
        listElement: {
          loanInstallmentAnuitySimulation: {
            downloading: false,
            message: "",
          },
        },
        animation: {
          proccesLoanInstallmentAnuitySimulation: {
            isLoading: false,
          },
        },
      },
    };
  },
  methods: {
    clearInputAndTableResponse() {
      this.dataForm.response.nominalLoan = 0;
      this.dataForm.response.totalRate = 0;
      this.dataForm.response.totalPeriod = 0;
      this.dataForm.response.totalInstallment = 0;
      this.table.data.installmentAunuitySimulation = [];
    },
    appendDefaultMessageTable() {
      this.property.listElement.loanInstallmentAnuitySimulation.message =
        "Belum Ada Data Yang Di Proses";
    },
    printInstallmentAnuitySimulation() {
      const doc = new jsPDF({
        orientation: "portrait",
        unit: "in",
        format: "letter",
      });
      const headerFinal = [];
      const header = this.table.header.installmentAunuitySimulation.map(
        (index) => {
          headerFinal.push(index.label);
        }
      );
      const finalDataTable = [];
      var numbering = 1;
      const loopDataTable = this.table.data.installmentAunuitySimulation.map(
        (index) => {
          finalDataTable.push([
            numbering,
            `${
              index.principalRemain
                ? index.principalRemain
                    .toFixed(2)
                    .replace(/\d(?=(\d{3})+\.)/g, "$&,")
                : 0
            }`,
            `${
              index.principal
                ? index.principal.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,")
                : 0
            }`,
            `${
              index.margin
                ? index.margin.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,")
                : 0
            }`,
            `${
              index.installment
                ? index.installment
                    .toFixed(2)
                    .replace(/\d(?=(\d{3})+\.)/g, "$&,")
                : 0
            }`,
          ]);
          numbering = numbering + 1;
        }
      );
      var pageWidth =
        doc.internal.pageSize.width || doc.internal.pageSize.getWidth();
      doc
        .setFontSize(10)
        .text("Data Simulasi angsuran", pageWidth / 2, 0.8, "center");
      doc
        .setFontSize(8)
        .text(
          `Nominal Pokok  :   ${
            this.dataForm.response.nominalLoan
              ? this.dataForm.response.nominalLoan
                  .toFixed(2)
                  .replace(/\d(?=(\d{3})+\.)/g, "$&,")
              : 0
          }`,
          0.55,
          1.3
        );
      doc
        .setFontSize(8)
        .text(
          `Angsuran Bulanan  :   ${
            this.dataForm.response.totalInstallment
              ? this.dataForm.response.totalInstallment
                  .toFixed(2)
                  .replace(/\d(?=(\d{3})+\.)/g, "$&,")
              : 0
          }`,
          0.55,
          1.5
        );

      doc
        .setFontSize(8)
        .text(
          `Jangka Waktu    :   ${this.dataForm.response.totalPeriod} Bulan`,
          0.55,
          1.7
        );

      doc
        .setFontSize(8)
        .text(
          `Total Rate          :   ${this.dataForm.response.totalRate} %`,
          0.55,
          1.9
        );

      doc.autoTable({
        columnStyles: {
          0: {
            halign: "center",
            font: "courier",
            fontSize: 6,
            fontStyle: "normal",
          },
          1: {
            halign: "center",
            font: "courier",
            fontSize: 6,
            fontStyle: "normal",
          },
          2: {
            halign: "center",
            font: "courier",
            fontSize: 6,
            fontStyle: "normal",
          },
          3: {
            halign: "center",
            font: "courier",
            fontSize: 6,
            fontStyle: "normal",
          },
          4: {
            halign: "center",
            font: "courier",
            fontSize: 6,
            fontStyle: "normal",
          },
        },
        headStyles: {
          halign: "center",
          fontSize: 6.4,
          fontStyle: "bold",
          font: "normal",
        },
        head: [headerFinal],
        theme: "striped",
        body: finalDataTable,
        margin: { top: 2.2 },
        halign: "center",
        didDrawPage: function(data) {
          data.settings.margin.top = 0.9;
        },
      });
      doc.autoPrint();

      const hiddFrame = document.createElement("iframe");
      hiddFrame.style.position = "fixed";
      // "visibility: hidden" would trigger safety rules in some browsers like safari，
      // in which the iframe display in a pretty small size instead of hidden.
      // here is some little hack ~
      hiddFrame.style.width = "1px";
      hiddFrame.style.height = "1px";
      hiddFrame.style.opacity = "0.01";
      const isSafari = /^((?!chrome|android).)*safari/i.test(
        window.navigator.userAgent
      );
      if (isSafari) {
        // fallback in safari
        hiddFrame.onload = () => {
          try {
            hiddFrame.contentWindow.document.execCommand("print", false, null);
          } catch (e) {
            hiddFrame.contentWindow.print();
          }
        };
      }
      hiddFrame.src = doc.output("bloburl");
      document.body.appendChild(hiddFrame);
    },
    async proccesIntallmentAnuitySimulation() {
      this.clearInputAndTableResponse();
      const payload = {
        nominalLoan: this.dataForm.request.nominalLoan,
        rate: this.dataForm.request.rate,
        loanPeriod: this.dataForm.request.loanPeriod,
      };
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      } else {
        this.property.animation.proccesLoanInstallmentAnuitySimulation.isLoading = true;
        this.property.listElement.loanInstallmentAnuitySimulation.downloading = true;
        try {
          const resp = await this.$store.dispatch({
            type: "POST_DATA",
            endPoint: "loan",
            reqUrl: "loan-installment/anuity-simulation",
            payload: payload,
          });
          setTimeout(() => {
            if (resp.data.code === "SUCCESS") {
              this.property.listElement.loanInstallmentAnuitySimulation.downloading = false;
              this.dataForm.response.nominalLoan = resp.data.data.nominalLoan;
              this.dataForm.response.totalRate = resp.data.data.totalRate;
              this.dataForm.response.totalPeriod = resp.data.data.totalPeriod;
              this.dataForm.response.totalInstallment =
                resp.data.data.totalInstallment;
              this.table.data.installmentAunuitySimulation =
                resp.data.data.detailInstallment;
            } else {
              this.$buefy.dialog.alert({
                title: "Gagal !",
                message: `${resp.data.message}`,
                confirmText: "Ok",
                type: "is-danger",
              });
            }
          }, 1500);
        } catch {
          this.$buefy.dialog.alert({
            title: "Gagal !",
            message: error.response
              ? `${error.response.statusText}`
              : "Terjadi Kesalahan",
            confirmText: "Ok",
            type: "is-danger",
          });
        } finally {
          setTimeout(() => {
            this.property.animation.proccesLoanInstallmentAnuitySimulation.isLoading = false;
          }, 1500);
        }
      }
    },
  },
  mounted() {
    this.appendDefaultMessageTable();
  },
};
